import React from "react"
import Layout from "./components/layout"
import LogoCarousel from "./components/LogoCarousel"
import { Container, Button, Row, Col, Carousel, CarouselItem } from "react-bootstrap"
import { Link } from "gatsby"
import ImageCarousel from "./components/ImageCarousel"

import Logo from "../images/logos/holzkette_logo.svg"

const IndexPage = () => {
  return (
    <Layout>
      <Container className="pt-5 pb-5">
        <Row>
          <Col className="text-white text-center">
            <img
              src={Logo}
              width={800}
              className="img-fluid"
              alt="Holzkette Logo"
            />
            <h1 className="mt-5">Eine Plattform Für Holz</h1>
            <h2>
              Vom Wald bis zum Händler,
              <br />
              digital und zentral!
            </h2>
            <Button
              href="mailto:info@telesis.at"
              role="button"
              className="bg-info mt-5 btn-lg"
            >
              Werde Teil der Holzkette
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-secondary pt-5 pb-5">
        <ImageCarousel/>
      </Container>
      <Container fluid className="bg-primary pt-5 pb-5">
        <LogoCarousel />
      </Container>
    </Layout>
  )
}

export default IndexPage
